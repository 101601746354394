import React from "react"
import MainNav from "../components/mainnav"
import { Link } from "gatsby"

const IndexPage = () => (
  <div>
    <div className="container max-w-full md:static sticky top-0 md:mb-20 mb:8 mx-auto items-center text-center">
      <MainNav />
    </div>
    <div className="md:absolute top-0 z-0 pb-20">
      <img
        src="../../header-photo-6.jpg"
        alt="Main"
        className="w-screen "
      ></img>
      <Link to="#Main-Content">
        <img
          src="../../down-arrow.png"
          alt="Main"
          className="md:sticky bottom-0 lg:visible invisible w-20 mx-auto object-bottom animate-bounce"
        ></img>
      </Link>
      <a id="Main-Content">
        <div className="container mx-auto overflow-auto lg:flex items-center border-8 border-white rounded-lg shadow-none">
          <div className="mx-auto">
            <p className="text-center md:text-6xl text-4xl mb-0 font-serif">
              Welcome
            </p>
            <p className="text-center md:text-4xl text-2xl mb-0 font-cursive">
              to our
            </p>
            <p className="text-center md:text-6xl text-4xl mb-0 font-brush">
              Wedding Website
            </p>
          </div>
        </div>
      </a>
      <div className="container text-center overflow-auto mx-auto items-center border-8 border-white rounded-lg shadow-2xl">
        <p className="mb-5 text-lg">
          We were prepared for a lot, but Coronavirus was not on the list! We
          sincerely hoped to celebrate with all our guests in person this year,
          however, we are taking health precautions as necessary and adjusted
          our wedding to family members who live in our area. We are happy to
          announce that we will be livestreaming our wedding ceremony so no one
          will miss out on the celebration. We hope you are healthy and well
          during this uncertain time and cannot wait to celebrate with you
          virtually.
        </p>
        <p className="mb-5 text-lg">
          We can’t wait to celebrate our special day with you whether virtually
          or with family in person. We have created this website as a convenient
          and interactive way to share all the important details with you in the
          lead up to our wedding. You can learn more about our{" "}
          <Link to="/virtual" className="text-ocean hover:underline">
            livestream details
          </Link>
          , read about{" "}
          <Link to="/ourstory" className="text-ocean hover:underline">
            our love story
          </Link>
          , and see details if you are attending{" "}
          <Link to="/inperson" className="text-ocean hover:underline">
            in person.
          </Link>
        </p>
        <p className="mb-5 text-lg">
          Please follow us on social media below and use the following hashtag
          to tag us in your virtual or in person photos:
        </p>
        <Link
          to="https://www.instagram.com/explore/tags/yallaeverafter/"
          className="text-ocean hover:underline mb-5 text-3xl font-bold text-center font-cursive"
        >
          #YallaEverAfter
        </Link>{" "}
        <p className="mx-auto mt-5 italic font-bold">Follow Alicia</p>
        <div className="sm:flex lg:mt-5 my-5 mx-auto justify-center">
          <a href="https://www.facebook.com/aliciadantin" className="px-5">
            <img
              src="../../facebook-logo.png"
              alt="PaypalLink"
              className="w-10 mx-auto"
            ></img>
          </a>
          <a href="https://www.instagram.com/running_fool85/" className="px-5">
            <img
              src="../../instagram-logo.png"
              alt="VenmoLink"
              className="w-10 mx-auto"
            ></img>
          </a>
        </div>
        <p className="mx-auto italic font-bold">Follow Yasser</p>
        <div className="sm:flex lg:mt-5 my-5 mx-auto justify-center">
          <a href="https://www.facebook.com/ynadeem" className="px-5">
            <img
              src="../../facebook-logo.png"
              alt="PaypalLink"
              className="w-10 mx-auto"
            ></img>
          </a>
          <a href="https://www.instagram.com/y.nadeem/" className="px-5">
            <img
              src="../../instagram-logo.png"
              alt="VenmoLink"
              className="w-10 mx-auto"
            ></img>
          </a>
        </div>
        <p className="mb-5 text-lg">
          Please view all the pages of our website and if you have any
          questions, contact us.
        </p>
        <p className="mb-5 text-lg italic">
          Thank you for your ongoing love and support during this process.
        </p>
        <p className="mb-5 text-md italic">
          If you would like to read more about why and how we built this
          website, please click{" "}
          <Link to="/about" className="text-ocean hover:underline mb-5">
            here
          </Link>
          .
        </p>
      </div>
    </div>
  </div>
)

export default IndexPage
